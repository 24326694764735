export default {
  name: 'SupportBtnProject',
  data: () => ({
    isShowDialog: false,
    supports: [
      {
        id: 1,
        link: 'https://pay.fondy.eu/s/UyRtC2wWm',
        src: 'frater_main_logo_gold.svg',
        width: '112',
        height: '118'
      },
      {
        id: 2,
        link: 'https://send.monobank.ua/jar/3Q1BZuQqPG',
        src: 'monobank_logo.png',
        width: '92',
        height: '92'
      }
    ]
  }),
  methods: {
    triggerDialog () {
      this.isShowDialog = !this.isShowDialog
    }
  }
}
