import screenBreakpoints from '@/assets/scss/export_vars.module.scss'

export default {
  data: () => ({
    windowWidth: 0,
  }),
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth)
  },
  computed: {
    xsOnly () {
      return this.windowWidth <= parseInt(screenBreakpoints.xs)
    },
    smAndDown () {
      return this.windowWidth <= parseInt(screenBreakpoints.sm)
    },
    smOnly () {
      return this.windowWidth > parseInt(screenBreakpoints.xs) && this.windowWidth <= parseInt(screenBreakpoints.sm)
    },
    smAndUp () {
      return this.windowWidth > parseInt(screenBreakpoints.xs)
    },
    mdAndDown () {
      return this.windowWidth <= parseInt(screenBreakpoints.md)
    },
    mdOnly () {
      return this.windowWidth > parseInt(screenBreakpoints.sm) && this.windowWidth <= parseInt(screenBreakpoints.md)
    },
    mdAndUp () {
      return this.windowWidth > parseInt(screenBreakpoints.sm)
    },
    lgAndDown () {
      return this.windowWidth <= parseInt(screenBreakpoints.lg)
    },
    lgOnly () {
      return this.windowWidth > parseInt(screenBreakpoints.md) && this.windowWidth <= parseInt(screenBreakpoints.lg)
    },
    lgAndUp () {
      return this.windowWidth > parseInt(screenBreakpoints.md)
    },
    xlOnly () {
      return this.windowWidth > parseInt(screenBreakpoints.lg)
    },
    screenBreakpoints () {
      return screenBreakpoints
    }
  }
}
