import HomePage from '@/pages/home-page/HomePage.vue'

export default {
  path: '/',
  name: 'HomePage',
  component: HomePage,
  beforeEnter: (to) => {
    if (to.hash) {
      return {
        path: to.path,
        query: to.query,
        hash: ''
      }
    }
  }
}
