import { createWebHistory, createRouter } from 'vue-router'

import homePage from './routes/home-page.js'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 100,
        behavior: 'smooth'
      }
    }
  },
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: () => import('@/components/main-container/MainContainer.vue'),
			children: [
				homePage
			]
		}
	]
})

export default router
