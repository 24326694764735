<template>
  <div class="carousel-indicators justify-center d-flex">
    <VSheet
      height="8" width="8"
      :class="[
        'flex-no-shrink pointer br-1',
        activeSlide === index ? 'active' : 'default'
      ]"
      v-for="(item, index) in numberToShow"
      :key="index"
      @click="switchSlide(index)"
    />
  </div>
</template>

<script src="./carousel-indicators.js"></script>

<style lang="scss" scoped>
.carousel-indicators {
  position: absolute;
  transform: translateX(-50%);
  gap: 10px;
  left: 50%;
  z-index: 2;
  width: 100%;
  bottom: 0px;
}
.active {
  background: #39364E;
}
.default {
  background: rgba($color: #39364E, $alpha: 0.3)
}
</style>
