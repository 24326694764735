import ApiBase from '@/api/base.js'

import { SOCIALS } from '@/data/data.js'
import { copyText } from '@/helpers/customFunctions.js'

export default {
  name: 'PosterSocials',
  data: () => ({
    hoveredSocialIcon: null,
    socials: [],
    socialsIds: [1, 4, 6]
  }),
  created () {
    this.initSocials()
  },
  computed: {
    isNavigatorShareAvailable () {
      return navigator.share
    },
    url () {
      return ApiBase.baseUrl()
    }
  },
  methods: {
    initSocials () {
      SOCIALS.forEach(social => {
        let isForPosters = this.socialsIds.some(id => id === social.id)
        if (isForPosters) this.socials.push(social)
      })
    },
    onMouseover (social) {
      this.hoveredSocialIcon = social
    },
    onMouseleave () {
      this.hoveredSocialIcon = null
    },
    getShareLink (social) {
      let link = this.url
      return social.share + link
    },
    copy () {
      const SUCCESS_MESSAGE = 'Посилання успішно скопійовано!'
      copyText(this, this.url, SUCCESS_MESSAGE)
    }
  }
}
