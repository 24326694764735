<template>
  <VImg width="24" height="24"
    src="arrow_left.svg"
    class="carousel-control left"
    @click="showPrevious"
  />
  <VImg width="24" height="24"
    src="arrow_right.svg"
    class="carousel-control right"
    @click="showNext"
  />
</template>

<script src="./carousel-controls.js"></script>
<style lang="scss" scoped>
.carousel-control {
  position: absolute;
  cursor: pointer;
  top: 90px;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
</style>
