import CarouselControls from './carousel-controls/CarouselControls.vue'
import CarouselIndicators from './carousel-indicators/CarouselIndicators.vue'

export default {
  name: 'VCarousel',
  components: {
    CarouselControls,
    CarouselIndicators
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 5000,
    },
    isTimer: {
      type: Boolean,
      default: false
    },
    startFromSlide: {
      type: [String, Number],
      default: 0
    },
    isResetSlider: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: 695
    }
  },
  data: () => ({
    currentSlide: 0,
    slideInterval: null,
    direction: 'right',
  }),
  mounted () {
    this.currentSlide = Number(this.startFromSlide)
    if (this.isTimer) this.startSlideTimer()
  },
  computed: {
    transitionEffect () {
      return this.direction === 'right' ? 'slide-out' : 'slide-in'
    },
    total () {
      return this.slides.length
    }
  },
  methods: {
    setCurrentSlide(index) {
      this.currentSlide = index
    },
    prev (index = this.currentSlide - 1) {
      if (index < 0) index = this.total + index
      this.setCurrentSlide(index)
      this.direction = 'left'
      if (this.isTimer) this.startSlideTimer()
    },
    _next (index = this.currentSlide + 1) {
      if (index + 1 > this.total) index = index - this.total
      this.setCurrentSlide(index)
      this.direction = 'right'
    },
    next (index = this.currentSlide + 1) {
      this._next(index)
      if (this.isTimer) this.startSlideTimer()
    },
    swipeNext () {
      this.next()
    },
    swipePrev () {
      this.prev()
    },
    startSlideTimer () {
      this.stopSlideTimer()
      this.slideInterval = setInterval(() => this._next(), this.interval)
    },
    stopSlideTimer () {
      clearInterval(this.slideInterval)
    },
    switchSlide (index) {
      const step = index - this.currentSlide
      if (step > 0) this.next(index)
      else this.prev(index)
    },
    mouseenterHandler () {
      this.$emit('mouseenter')
    },
    mouseoutHandler () {
      this.$emit('mouseout')
    }
  },
  watch: {
    isResetSlider (value) {
      if (value) this.currentSlide = 0
    }
  },
  beforeUnmount () {
    if (this.isTimer) this.stopSlideTimer()
  }
}
