<template>
<div class="preview-section d-flex flex-column justify-end">
  <div
    v-for="slide in slides"
    :key="slide.id"
  >
    <transition name="fade">
      <VImg
        v-show="currentSlide.id === slide.id"
        isCover
        width="100%" height="100dvh"
        :src="slide.src"
        class="preview-img"
      />
    </transition>
  </div>
  <div :class="[
      'white-04 primary-blur pb-5',
      smAndDown ? 'text-center pt-5' : 'pt-10'
    ]"
  >
    <div class="container white--text relative">
      <div class="content">
        <div class="d-flex align-center justify-space-between gap-5">
          <div class="w-100">
            <h2 class="primary-title top">
              Ти - ветеран і маєш постійний біль?
            </h2>
            <h3 class="primary-subtitle">
              <span>
                Разом з
              </span>
              <span class="primary--text">
                #БезБолюUA
              </span>
              <span>
                отримай швидку безкоштовну допомогу кращих фахівців.
              </span>
            </h3>
            <a
              target="_blank"
              href="https://t.me/stoppainuabot"
              :class="[
                '',
                smAndDown ? 'd-block mt-5 mb-2' : 'd-inline-block my-5'
              ]"
            >
              <VBtn
                :block="smAndDown"
                width="220" height="54"
                class="preview-btn"
              >
                <span class="dark--text">
                  Отримати допомогу
                </span>
              </VBtn>
            </a>
            <VideoComponent v-if="smAndDown" class="mb-5" />
            <h2 class="primary-title">
              Ти - медик і тобі потрібна консультація щодо болю?
            </h2>
          </div>
          <VideoComponent v-if="mdAndUp" />
        </div>
      </div>
    </div>
  </div>
  <div class="preview-footer black-04 primary-blur d-flex align-center">
    <div class="container relative">
      <div class="content">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center gap-5">
            <a href="https://frater.ngo" target="_blank">
              <VImg
                isIcon
                :background="isLogoHovered ? 'primary' : 'white'"
                width="40" height="42"
                src="frater_main_logo.svg"
                class="frater-logo"
                @mouseenter="isLogoHovered = true"
                @mouseout="isLogoHovered = false"
              />
            </a>
            <p class="secondary-content">
              #БезБолюUA - спільний проєкт ГО “ФРАТЕР” та лікарів-альгологів.
            </p>
          </div>
          <SocialsComponent
            v-if="mdAndUp"
            primaryColor="white"
            hoverColor="primary"
            textColor="white-80"
          />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./preview-section.js"></script>

<style lang="scss" scoped>
.preview-section {
  min-height: 100dvh;

  .preview-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .primary-title {
    color: $white !important;
    font-size: 22px;

    &.top {
      margin-bottom: 10px;
    }
  }

  .primary-subtitle {
    color: $white !important;
    font-size: 18px !important;
    line-height: 21px !important;
  }

  .primary-content {
    color: $white !important;
  }

  .preview-btn {
    border-radius: 10px !important;
    background-color: $white !important;
    transition: .2s linear;

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.8) !important;
    }
  }

  .preview-footer {
    height: 82px;
  }

  .secondary-content {
    color: rgba($color: $white, $alpha: 0.8) !important;
  }

  .frater-logo {
    transition: .2s linear;
  }
}

.fade-enter-active {
  animation: fade-in 2s;
}

.fade-leave-active {
  animation: fade-in 2s reverse;
}

@keyframes fade-in  {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: $sm) {
  .preview-section {
    .primary-subtitle {
      font-size: 16px !important;
      line-height: 19px !important;
    }

    .primary-content {
      font-size: 12px !important;
      line-height: 14px !important;
    }
  }
}
</style>
