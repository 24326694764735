import HelpStep from './help-step/HelpStep.vue'

export default {
  name: 'HelpPathSection',
  components: {
    HelpStep
  },
  data: () => ({
    steps: [
      {
        id: 1,
        value: 1,
        desc: '<p>Перейди в чат</p><p>та натисни “старт”</p>',
        src: 'help_step_bg_1.jpg'
      },
      {
        id: 2,
        value: 2,
        desc: '<p>Заповни анкету</p>',
        src: 'help_step_bg_2.jpg'
      },
      {
        id: 3,
        value: 3,
        desc: '<p>З тобою зв’яжеться лікар</p><p>у зручний для тебе час*</p>',
        src: 'help_step_bg_3.jpg'
      }
    ]
  })
}
