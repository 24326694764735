import { EXPERTS } from '@/data/data.js'

export default {
  name: 'ExpertsSection',
  data: () => ({
    EXPERT_WIDTH: 230,
    AUTO_SCROLL_INTERVAL: 2000,
    expertsBoxId: 'experts_box',
    isHorizontalScrollExist: false,
    isShowLeftBtn: false,
    isShowRightBtn: true,
    isScrollToLeft: false,
    isShowDialog: false,
    intervalId: null,
    experts: EXPERTS,
    selectedExpert: null
  }),
  mounted () {
    setTimeout(this.runAutoScroll, 1000)
  },
  computed: {
    columnGap () {
      return this.smAndDown ? 20 : 40
    }
  },
  methods: {
    runAutoScroll () {
      if (this.mdAndDown || !this.isHorizontalScrollExist) return

      this.intervalId = setInterval(() => {
        if (!this.isScrollToLeft && this.isShowRightBtn) {
          this.scrollToDirection()
        } else if (this.isScrollToLeft && !this.isShowLeftBtn) {
          this.scrollToDirection()
        } else this.scrollToDirection(true)
      }, this.AUTO_SCROLL_INTERVAL)
    },
    updateIsShowButtons (isLeft, element) {
      if (!element) return
      let elementProperties = element.getBoundingClientRect()
      let scrollLeft = element.scrollLeft
      let width = elementProperties.width
      let scrollWidth = element.scrollWidth

      if (isLeft && !scrollLeft) this.isShowLeftBtn = false
      else if  (scrollWidth - scrollLeft === width) {
        this.isShowRightBtn = false
      }
    },
    manualScrollToDirection (isLeft = false) {
      if (this.intervalId) clearInterval(this.intervalId)
      this.scrollToDirection(isLeft)
    },
    scrollToDirection (isLeft = false) {
      isLeft ? this.isShowRightBtn = true : this.isShowLeftBtn = true

      this.isScrollToLeft = isLeft
      let cardContainer = document.getElementById(this.expertsBoxId)
      if (!cardContainer) return
      let distance = this.EXPERT_WIDTH + this.columnGap
      cardContainer.scrollBy({
        left:  isLeft ? -distance: distance,
        behavior: 'smooth'
      })
      setTimeout(() => {
        this.updateIsShowButtons(isLeft, cardContainer)
      }, 300)
    },
    openDialog (expert) {
      this.selectedExpert = expert
      this.isShowDialog = true
    },
    closeDialog () {
      this.selectedExpert = null
      this.isShowDialog = false
    }
  },
  watch: {
    windowWidth () {
      let cardContainer = document.getElementById(this.expertsBoxId)
      if (!cardContainer) return
      setTimeout(() => {
        this.isHorizontalScrollExist = cardContainer.scrollWidth > cardContainer.clientWidth
      }, 300)
    }
  },
  beforeUnmount () {
    clearInterval(this.intervalId)
  }
}
