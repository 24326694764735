<template>
<div class="problem-description">
  <div class="container">
    <div class="content">
      <h2 class="primary-title text-center">
        Про проблему
      </h2>
      <h3 class="primary-subtitle text-center mb-10">
        Що таке “біль” і “хронічний біль”?
      </h3>
      <AccordionComponent
        :itemList="content"
        class="accordion"
      />
    </div>
  </div>
</div>
</template>

<script src="./problem-description.js"></script>

<style lang="scss" scoped>
.primary-title {
  margin-bottom: 10px;
}

@media only screen and (max-width: $xs) {
  .primary-subtitle {
    max-width: 255px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
