class ApiBase {
  static baseUrl () {
		return process.env.VUE_APP_BASE_URL
	}

  static setToStore (self, name, data, callBack) {
    self.$store.dispatch(name, data)
    if (callBack) {
      callBack()
    }
  }

  static unAuthorizeUser (self, redirectTo) {
		if (redirectTo) {
			self.$router.push(redirectTo)
		} else {
      self.$router.push('/')
    }
	}

  static appTitle () {
    return process.env.VUE_APP_TITLE
  }
}

export default ApiBase
