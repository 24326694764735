<template>
<div class="home-page">
  <PreviewSection id="preview_section" />
  <HelpPathSection class="section-padding" />
  <ProjectSection id="project_section" class="section-padding section-anchor" />
  <ExpertsSection id="experts_section" class="section-padding section-anchor" />
  <div class="custom-divider" />
  <ProblemDescription id="problem_section" class="section-padding section-anchor" />
  <div class="custom-divider" />
  <PosterSection class="section-padding" />
  <div class="custom-divider" />
  <PartnersSection id="partners_section" class="section-padding section-anchor" />
</div>
</template>

<script src="./home-page.js"></script>
