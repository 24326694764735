import { PARTNERS } from '@/data/data.js'

export default {
  name: 'PartnersSection',
  data: () => ({
    isHorizontalScrollExist: false,
    partnersBoxId: 'partners_box',
    partners: PARTNERS
  }),
  watch: {
    windowWidth () {
      let cardContainer = document.getElementById(this.partnersBoxId)
      if (!cardContainer) return
      setTimeout(() => {
        this.isHorizontalScrollExist = cardContainer.scrollWidth > cardContainer.clientWidth
      }, 300)
    }
  }
}
