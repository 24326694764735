import PreviewSection from '@/components/home-page/preview-section/PreviewSection.vue'
import HelpPathSection from '@/components/home-page/help-path-section/HelpPathSection.vue'
import ProjectSection from '@/components/home-page/project-section/ProjectSection.vue'
import ExpertsSection from '@/components/home-page/experts-section/ExpertsSection.vue'
import ProblemDescription from '@/components/home-page/problem-description/ProblemDescription.vue'
import PosterSection from '@/components/home-page/poster-section/PosterSection.vue'
import PartnersSection from '@/components/home-page/partners-section/PartnersSection.vue'

export default {
  name: 'HomePage',
  components: {
    PreviewSection,
    HelpPathSection,
    ProjectSection,
    ExpertsSection,
    ProblemDescription,
    PosterSection,
    PartnersSection
  }
}
