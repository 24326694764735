<template>
  <div
    class="v-sheet"
    :style="{
      width: getParsedValue(width),
      height: getParsedValue(height),
      maxWidth: maxWidth === '100%' ? '100%' : maxWidth + 'px',
      maxHeight: maxHeight + 'px',
      minWidth: minWidth + 'px',
      minHeight: minHeight + 'px',
    }"
  >
    <slot></slot>
  </div>
</template>

<script src="./v-sheet.js"></script>
