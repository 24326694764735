<template>
<div>
  <span
    v-if="smAndDown"
    :class="[
      'relative text-body-5 d-inline-block mb-5',
      textColor + '--text'
  ]"
  >
    Слідкуй за новинами проєкту на Facebook!
  </span>
  <div
    v-if="socials && socials.length"
    class="socials d-flex gap-5 flex-wrap justify-center align-center"
  >
    <a
      target="_blank"
      class="social d-inline-block relative"
      :style="{ height: size + 'px' }"
      :href="facebook.link"
    >
      <VImg
        isIcon
        :width="size" :height="size"
        :src="facebook.src"
        :background="hoveredSocialIcon === facebook.id ? hoverColor : primaryColor"
        @mouseover="onMouseover(facebook)"
        @mouseleave="onMouseleave"
        class="social-icon"
      />
    </a>
    <span
      v-if="mdAndUp"
      :class="[
        'text-body-5',
        textColor + '--text'
    ]"
    >
      Слідкуй за новинами проєкту на Facebook!
    </span>
    <a
      v-for="social in otherSocials"
      :key="social.id"
      target="_blank"
      class="social d-inline-block relative"
      :style="{ height: size + 'px' }"
      :href="social.link"
    >
      <VImg
        isIcon
        :width="size" :height="size"
        :src="social.src"
        :background="hoveredSocialIcon === social.id ? hoverColor : primaryColor"
        @mouseover="onMouseover(social)"
        @mouseleave="onMouseleave"
        class="social-icon"
      />
    </a>
  </div>
</div>
</template>

<script src="./socials-component.js"></script>
