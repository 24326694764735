import SupportBtnProject from '@/components/support-btn-project/SupportBtnProject.vue'

import { WORKERS } from '@/data/data.js'

export default {
  name: 'ProjectSection',
  components: {
    SupportBtnProject
  },
  data: () => ({
    workers: WORKERS
  })
}
