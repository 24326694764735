import PosterSocials from './poster-socials/PosterSocials.vue'
import PosterPopup from './poster-popup/PosterPopup.vue'

import { POSTERS } from '@/data/data.js'

export default {
  name: 'PosterSection',
  components: {
    PosterSocials,
    PosterPopup
  },
  data: () => ({
    currentPoster: {},
    posters: POSTERS,
    isShowDialog: false
  }),
  methods: {
    setPoster (poster) {
      this.currentPoster = poster
    },
    openDialog () {
      this.isShowDialog = true
    },
    closeDialog () {
      this.isShowDialog = false
    }
  }
}
