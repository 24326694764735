export default {
  name: 'VInput',
  props: {
    modelValue: {
      type: [Number, String]
    },
    placeholder: {
      type: String,
      default: '0.0'
    },
    rules: {
      type: Function
    },
    height: {
      type: [String, Number],
      default: 54
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: 'v_input'
    }
  },
  data: ()=> ({
    errors: []
  }),
  methods: {
    localValidation (rules) {
      let isValidate = true
      rules.forEach(item => {
        let validation = item(this.modelValue)
        if (typeof(validation) === 'string') {
          if (!this.errors) this.errors = []
          isValidate = false
          this.errors.push(validation)
        }
      })
      if (isValidate) this.errors = []
    },
    updateInputValue (event) {
      this.$emit('update:modelValue', event.target.value)
    },
    clearErrors () {
      this.errors = []
    }
  },
  watch: {
    modelValue () {
      if (this.rules) {
        this.localValidation(this.rules())
        this.$emit('set-valid-value', this.errors.length <= 0)
      }
    }
  }
}
