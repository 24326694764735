<template>
<div
  class="v-loader"
  :style="{
    width: size + 'px',
    height: size + 'px',
  }"
>
  <div
    class="loader-circle big-circle"
    :style="{
      width: size + 'px',
      height: size + 'px',
      borderWidth: lineWidth + 'px',
      borderBottomColor: bigCircleColor,
      borderRightColor: bigCircleColor
    }"
  />
  <div
    class="loader-circle small-circle"
    :style="{
      width: size / 1.75 + 'px',
      height: size / 1.75 + 'px',
      borderWidth: lineWidth + 'px',
      borderBottomColor: smallCircleColor,
      borderRightColor: smallCircleColor
    }"
  />
</div>
</template>

<script src="./v-loader.js"></script>

<style lang="scss" scoped>
.v-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.loader-circle {
  position: absolute;
	border-style: solid;
	border-radius: 50%;
}
.big-circle {
	border-top-color: transparent;
	border-left-color: transparent;
	animation: big-circle-animation 1.5s linear infinite;
  top: 0;
  left: 0;
}
.small-circle {
	border-top-color: transparent;
	border-left-color: transparent;
	animation: small-circle-animation 1.35s linear  infinite;
}

@keyframes small-circle-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
@keyframes big-circle-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
