export default {
  name: 'VTextarea',
  props: {
    modelValue: {
      type: [Number, String]
    },
    placeholder: {
      type: String,
      default: '0.0'
    },
    rules: {
      type: Function
    },
    name: {
      type: String,
      default: 'v_input'
    },
    maxHeight: {
      type: [String, Number],
      default: 100
    }
  },
  data: ()=> ({
    errors: [],
    VERTICAL_PADDING: 14,
    LINE_HEIGHT: 24,
    BORDER: 1
  }),
  mounted () {
    this.setHeight()
  },
  methods: {
    localValidation (rules) {
      let isValidate = true
      rules.forEach(item => {
        let validation = item(this.modelValue)
        if (typeof(validation) === 'string') {
          if (!this.errors) this.errors = []
          isValidate = false
          this.errors.push(validation)
        }
      })
      if (isValidate) this.errors = []
    },
    updateInputValue (event) {
      this.setHeight()
      this.$emit('update:modelValue', event.target.value)
    },
    setHeight () {
      let textarea = this.$refs.textarea
      let rows = textarea.value.split('\n').length
      let height = rows * this.LINE_HEIGHT + 2 * this.VERTICAL_PADDING + 2 * this.BORDER
      height = Math.min(height, Number(this.maxHeight))
      textarea.style.height = Number(height) + 'px'
    },
    clearErrors () {
      this.errors = []
    }
  },
  watch: {
    modelValue () {
      if (this.rules) {
        this.localValidation(this.rules())
        this.$emit('set-valid-value', this.errors.length <= 0)
      }
    }
  }
}
