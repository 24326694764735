export default {
  name: 'VLoader',
  props: {
    size: {
      type: [String, Number],
      default: '70'
    },
    lineWidth: {
      type: [String, Number],
      default: '5'
    },
    bigCircleColor: {
      type: [String, Number],
      default: '#DBC9B0'
    },
    smallCircleColor: {
      type: [String, Number],
      default: '#DBC9B0'
    }
  }
}
