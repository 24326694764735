<template>
<div class="partners-section">
  <div :class="[
      'container',
      isHorizontalScrollExist ? 'full-width' : ''
    ]"
  >
    <div :class="[
        'content relative',
        isHorizontalScrollExist ? 'full-width' : ''
      ]"
    >
      <h2 class="primary-title text-center mb-10">
        Наші партнери
      </h2>
      <div :id="partnersBoxId" class="partners-wrap overflow-y-hidden relative">
        <div
          :class="[
            'partners d-flex align-center gap-10',
            isHorizontalScrollExist ? '' : 'justify-center'
          ]"
        >
          <div
            v-for="partner in partners"
            :key="partner.id"
            class="partner relative"
          >
            <a
              v-if="partner.link"
              target="_blank" :href="partner.link"
            >
              <VImg
                width="112" height="118"
                :src="partner.src"
              />
            </a>
            <VImg
              v-else
              width="112" height="118"
              :src="partner.src"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./partners-section.js"></script>

<style lang="scss" scoped>
.partners-wrap {
  padding-bottom: 20px;
  margin-bottom: -20px;
}

@media only screen and (max-width: $sm) {
  .container.full-width,
  .content.full-width {
    padding: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .partner {
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
  }
}
</style>
