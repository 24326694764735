<template>
<div :class="[
    'poster-popup white pt-5 pb-10',
    smAndDown ? 'px-5' : 'px-10'
  ]"
>
  <h4 class="modal-title mb-5">
    Скачай постер і стань нашим амбасадором
  </h4>
  <div class="preview-box d-flex gap-5 align-center justify-center flex-wrap">
    <VSheet
      v-for="poster in posters"
      :key="poster.id"
      width="104" height="147"
      class="preview-item"
    >
      <a :href="poster.link" target="_blank">
        <VImg
          width="104" height="147"
          :src="poster.src"
        />
      </a>
    </VSheet>
  </div>
</div>
</template>

<script src="./poster-popup.js"></script>

<style lang="scss" scoped>
.preview-item {
  border-radius: 8px;
  transition: .4s linear;
  overflow: hidden;
  z-index: 1;

  &:hover {
    transform: scale(0.97);
  }
}

@media only screen and (max-width: $sm) {
  .modal-title {
    width: 90%;
  }
  .preview-box {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>
