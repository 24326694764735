export default {
  name: 'CarouselControls',
  emits: ['prev-slide', 'next-slide'],
  methods: {
    showPrevious () {
      this.$emit('prev-slide')
    },
    showNext () {
      this.$emit('next-slide')
    }
  }
}
