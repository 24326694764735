import VBtnElement from '@/components/global-components/v-btn/VBtn.vue'
import VImgElement from '@/components/global-components/v-img/VImg.vue'
import VSheetElement from '@/components/global-components/v-sheet/VSheet.vue'
import VCarouselElement from '@/components/global-components/v-carousel/VCarousel.vue'
import VDialogElement from '@/components/global-components/v-dialog/VDialog.vue'
import VInputElement from '@/components/global-components/v-input/VInput.vue'
import VTextareaElement from '@/components/global-components/v-textarea/VTextarea.vue'
import VLoaderElement from '@/components/global-components/v-loader/VLoader.vue'

function addGlobalComponents (app) {
  app.component('VBtn', VBtnElement)
  app.component('VImg', VImgElement)
  app.component('VSheet', VSheetElement)
  app.component('VCarousel', VCarouselElement)
  app.component('VDialog', VDialogElement)
  app.component('VInput', VInputElement)
  app.component('VTextarea', VTextareaElement)
  app.component('VLoader', VLoaderElement)
}

export { addGlobalComponents }
