<template>
<div class="mx-auto v-textarea relative">
  <textarea
    ref="textarea"
    :name="name"
    type="textarea"
    :placeholder="placeholder"
    :class="[
      'default-input',
      errors && errors.length ? 'primary--text' : 'black--text'
    ]"
    :style="{
      paddingTop: VERTICAL_PADDING + 'px',
      paddingBottom: VERTICAL_PADDING + 'px'
    }"
    :value="modelValue"
    @input="updateInputValue"
  />
  <p
    v-if="errors && errors.length"
    class="input-error-block error--text"
    v-html="errors && errors[0]"
  />
</div>
</template>

<script src="./v-textarea.js" ></script>

<style lang="scss" scoped>
.v-textarea {
  border-radius: 10px;
}
.default-input {
  resize: none;
  line-height: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}
</style>
