<template>
<div>
  <div @click="triggerDialog">
    <slot>
      <VBtn
        :block="smAndDown"
        width="220" height="54"
        class="primary-btn"
      >
        <span class="dark--text">
          Підтримати проєкт
        </span>
      </VBtn>
    </slot>
  </div>
  <VDialog
    v-model="isShowDialog"
    @close-dialog="triggerDialog"
  >
    <div :class="[
        'white pt-5 pb-10',
        smAndDown ? 'px-5' : 'px-10'
      ]"
    >
      <h4 class="modal-title mb-5">
        Підтримати проєкт
      </h4>
      <p class="primary-content">
        Допоможіть нам втілити нашу ідею, розвинути проєкт і продовжувати робити наш світ кращим місцем для всіх!
      </p>
      <div :class="[
          'd-flex justify-center flex-wrap mt-10',
          smAndDown ? 'gap-5' : 'gap-10 mb-10'
        ]"
      >
        <a
          v-for="support in supports"
          :key="support.id"
          target="_blank"
          :href="support.link"
        >
          <VSheet
            width="280" height="198"
            class="support d-flex align-center justify-center pointer"
          >
            <VImg
              :width="support.width"
              :height="support.height"
              :src="support.src"
            />
          </VSheet>
        </a>
      </div>
    </div>
  </VDialog>
</div>
</template>

<script src="./support-btn-project.js"></script>

<style lang="scss" scoped>
.support {
  border-radius: 10px;
  border: 1px solid rgba($color: $light-dark, $alpha: 0.2);
  transition: .2s linear;

  &:hover {
    background-color: rgba($color: $light-dark, $alpha: 0.05);
  }
}
</style>
