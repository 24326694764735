export default {
  name: "VSheet",
  props: {
    width: {
      type: [String, Number],
      default: "auto",
    },
    height: {
      type: [String, Number],
      default: "auto",
    },
    maxWidth: {
      type: [String, Number],
    },
    maxHeight: {
      type: [String, Number],
    },
    minWidth: {
      type: [String, Number],
    },
    minHeight: {
      type: [String, Number],
    },
  },
  methods: {
    getParsedValue (val) {
      val = String(val)
      if (val === 'auto') return 'auto'
      return val.includes('%')
        ? Number(val.replace('%', '')) + '%'
        : Number(val) + 'px'
    }
  }
}
