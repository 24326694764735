import { SOCIALS } from '@/data/data.js'

export default {
  name: 'SocialsComponent',
  props: {
    size: {
      type: [String, Number],
      default: 24
    },
    primaryColor: {
      type: String,
      default: 'primary'
    },
    hoverColor: {
      default: 'secondary'
    },
    textColor: {
      type: String,
      default: 'primary'
    }
  },
  data: () => ({
    hoveredSocialIcon: null
  }),
  computed: {
    socials () {
      return Array.from(SOCIALS).filter(social => social.link)
    },
    facebook () {
      return this.socials.find(social => social.id === 1)
    },
    otherSocials () {
      return this.socials.filter(social => social.id !== 1)
    }
  },
  methods: {
    onMouseover (social) {
      this.hoveredSocialIcon = social.id
    },
    onMouseleave () {
      this.hoveredSocialIcon = null
    }
  }
}
