<template>
<div class="mx-auto v-input relative">
  <input
    :name="name"
    :placeholder="placeholder"
    :class="[
      'default-input',
      errors && errors.length ? 'primary--text' : 'black--text'
    ]"
    :style="{ height: height + 'px' }"
    :type="type"
    :value="modelValue"
    @input="updateInputValue"
  />
  <p
    v-if="errors && errors.length"
    class="input-error-block error--text"
    v-html="errors && errors[0]"
  />
</div>
</template>

<script src="./v-input.js" ></script>

<style lang="scss" scoped>
.v-input {
  border-radius: 10px;
}
</style>
