import store from '@/store'

import { notify } from '@/helpers/notifyHelper.js'

export default {
  computed: {
    state () {
			return store.getters
		}
  },
  methods: {
    notify (type, message) {
      notify(this, type, message)
    },
    openMenuHandle (isVisible) {
      let body = document.body
      let header = document.getElementById('main_header')
      if (!body || !header) return

      if (isVisible) {
        body.classList.add('body-modal-open')
        header.classList.add('header-modal-open')
      }
      else {
        body.classList.remove('body-modal-open')
        header.classList.remove('header-modal-open')
      }
    }
  },
  watch: {
    isShowDialog (isVisible) {
      this.openMenuHandle(isVisible)
    },
    isShowLibraryDialog (isVisible) {
      this.openMenuHandle(isVisible)
    },
    isShowRehabilitationDialog (isVisible) {
      this.openMenuHandle(isVisible)
    }
  }
}
