import YouTube from 'vue3-youtube'

export default {
  name: 'VideoComponent',
  components: {
    YouTube
  },
  props: {
    src: {
      type: String,
      default: 'https://www.youtube.com/embed/B9jwCGDLVSM'
    }
  },
  data: () => ({
    isShowDialog: false,
    isLoading: true,
    intervalId: null
  }),
  methods: {
    onReady() {
      this.isLoading = false
      this.$refs.youtube.playVideo()
    },
    onError () {
      this.isLoading = false
      this.notify('error', 'Помилка при завантаженні відео')
    },
    toggleDialog () {
      this.isShowDialog = !this.isShowDialog
    }
  }
}
