<template>
<div class="experts-section">
  <div :class="[
      'container',
      isHorizontalScrollExist ? 'full-width' : ''
    ]"
  >
    <div :class="[
        'content relative',
        isHorizontalScrollExist ? 'full-width' : ''
      ]"
    >
      <h2 :class="[
          'primary-title text-center',
          smAndDown ? 'mb-10' : 'mb-20'
        ]"
      >
        Наші експерти
      </h2>
      <VImg
        v-if="lgAndUp && isHorizontalScrollExist && isShowRightBtn"
        width="64" height="64"
        src="scroll_right_icon.svg"
        class="scroll-right-icon pointer"
        @click="manualScrollToDirection()"
      />
      <VImg
        v-if="lgAndUp && isHorizontalScrollExist && isShowLeftBtn"
        width="64" height="64"
        src="scroll_left_icon.svg"
        class="scroll-left-icon pointer"
        @click="manualScrollToDirection(true)"
      />
      <div :id="expertsBoxId" class="experts-wrap overflow-y-hidden relative">
        <div
          :class="[
            'experts d-flex',
            smAndDown ? 'gap-5' : 'gap-10',
            isHorizontalScrollExist ? '' : 'justify-center'
          ]"
        >
          <div
            v-for="expert in experts"
            :key="expert.id"
            class="expert relative"
          >
            <VSheet
              width="230" height="230"
              class="expert-img-wrap relative"
            >
              <VImg
                width="230" height="230"
                :src="expert.src"
                class="expert-img br-half overflow-hidden mb-10"
              />
            </VSheet>
            <VSheet
              width="42" height="42"
              class="info-wrap d-flex align-center justify-center br-half primary pointer"
              @click="openDialog(expert)"
            >
              <VImg
                width="24" height="24"
                src="info_icon.svg"
              />
            </VSheet>
            <div class="primary-content font-weight-600 text-center mt-10">
              <span class="d-block">
                {{ expert.lastName }}
              </span>
              <span class="d-block">
                {{ expert.firstName }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VDialog
    v-model="isShowDialog"
    @close-dialog="closeDialog"
  >
    <div :class="[
        'white pt-5 pb-10',
        smAndDown ? 'px-5' : 'px-10'
      ]"
    >
      <h4 class="modal-title mb-5">
        {{ selectedExpert.lastName }}
        <br v-if="smAndDown" />
        {{ selectedExpert.firstName }}
      </h4>
      <p class="primary-content">
        {{ selectedExpert.desc }}
      </p>
    </div>
  </VDialog>
</div>
</template>

<script src="./experts-section.js"></script>

<style lang="scss" scoped>
::-webkit-scrollbar-thumb {
  background: transparent;
}

.info-wrap {
  position: absolute;
  top: 0;
  right: 35px;
}

.scroll-right-icon {
  position: absolute;
  top: 200px;
  left: calc(100% + 40px);
}

.scroll-left-icon {
  position: absolute;
  top: 200px;
  right: calc(100% + 40px);
}

.expert-img-wrap {
  border: 5px solid rgba($color: $primary, $alpha: 0.4);
  border-radius: 50%;
}

.expert-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media only screen and (max-width: $md) {
  ::-webkit-scrollbar-thumb {
    background: $primary;
  }

  .container.full-width,
  .content.full-width {
    padding: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .experts-wrap {
    padding-bottom: 20px;
    margin-bottom: -20px;
  }

  .expert {
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;

      .info-wrap {
        right: 55px;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .experts {
    padding-bottom: 10px;
    margin-bottom: -10px;
  }
}
</style>
