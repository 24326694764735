export default {
  name: 'CarouselIndicators',
  props: {
    total: {
      type: [String, Number],
      required: true
    },
    currentIndex: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    numberToShow () {
      return this.total
    },
    activeSlide () {
      return this.currentIndex
    }
  },
  methods: {
    switchSlide (index) {
      if (index === this.activeSlide) return
      if(this.isMoreThenNumberToShow) {
        index = this.currentIndex + (index - this.activeSlide)
      }
      this.$emit('switch', index)
    }
  }
}
