import router from './router'
import { createApp } from 'vue'
import store from './store'
import App from './App.vue'

import { addGlobalComponents } from './plugins/globalComponentsImport.js'
import axiosMixin from './plugins/axios'
import vueMixin from './plugins/vueMixin'
import breakpointsMixin from './plugins/breakpoints'
import Notifications from '@kyvg/vue3-notification'
import Vue3TouchEvents from 'vue3-touch-events'

import '@/assets/scss/app.scss'
import './plugins/axios'

const app = createApp(App)

addGlobalComponents(app)
app.use(router)
app.use(store)
app.use(Notifications)
app.use(Vue3TouchEvents)
app.mixin(vueMixin)
app.mixin(axiosMixin)
app.mixin(breakpointsMixin)
app.mount('#app')

app.directive('click-outside', {
  beforeMount(el, binding, vnode) {
    vnode
    el.clickOutsideEvent = function(event) {
      if (binding.value) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})
