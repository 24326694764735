import axios from 'axios'

export default {
  methods: {
		http (method, url, params, header, progressCallback) {
			method = method.toLowerCase()
			const config = {
				method: method,
				url
			}

			if (method === 'get') {
				config.params = params
			} else if (method === 'post' || method === 'put' || method === 'delete') {
				config.data = params
			}

			config.headers = Object.assign(header || {})

      if (progressCallback) {
        config.onUploadProgress = progressCallback
      }

			return new Promise((resolve, reject) => {
				return axios(config)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (error.response) {
							switch (error.response.status) {
								case 401:
									this.notify('error', error.response.data.message)
                  reject(error)
									break
								case 403:
									this.notify('error', error.response)
									reject(error)
									break
								case 404:
									this.notify('error', error.response.data && error.response.data.message)
									reject(error.response.data)
									break
								case 405:
									this.notify('error', error.response)
									reject(error)
									break
								case 422:
									this.notify('error', error.response.data && error.response.data.message)
									reject(error)
									break
								default:
									this.notify('error', error.response)
									reject(error)
									break
							}
						} else {
							console.error(error)
							reject(error)
						}
					})
			})
		}
	}
}
