export default {
  name: 'VImg',
  props: {
    src: {
      type: String
    },
    width: {
      type: [String, Number]
    },
    height: {
      type: [String, Number]
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    background: {
      type: String
    },
    external: {
      type: Boolean,
      default: false
    },
    isCover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url () {
      return this.external
        ? this.src
        : require('@/assets/images/' + this.src)
    }
  },
  methods: {
    getParsedValue (value) {
      value = String(value)
      if (value.includes('dvh')) {
        return Number(value.replace('dvh', '')) + 'dvh'
      }

      if (value.includes('%')) {
        return Number(value.replace('%', '')) + '%'
      }

      return Number(value) + 'px'
    }
  }
}
