export default {
  name: 'VDialog',
  props: {
    modelValue: {
      type: Boolean,
      required: false
    },
    maxWidth: {
      type: [String, Number],
      default: '680'
    }
  },
  data: () => ({
    isCloseHovered: false
  }),
  mounted () {
    document.body.addEventListener('keydown', this.onEscapeHandler)
  },
  methods: {
    onEscapeHandler (event) {
      if (this.modelValue && event.keyCode === 27) {
        this.closeDialog()
      }
    },
    closeDialog () {
      this.$emit('close-dialog')
    }
  },
  destroyed () {
    document.body.removeEventListener('keydown', this.onEscapeHandler)
  }
}
