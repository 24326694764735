<template>
<div class="project-section overflow-hidden relative primary-01">
  <VImg
    v-if="mdAndUp"
    isCover
    width="100%"
    src="project_section_pixel.png"
    class="project-bg"
  />
  <VImg
    v-if="smAndDown"
    isCover
    width="714" height="639"
    src="project_section_mobile_pixel.png"
    class="pixel-1"
  />
  <VImg
    v-if="smAndDown"
    isCover
    width="714" height="639"
    src="project_section_mobile_pixel.png"
    class="pixel-2"
  />
  <div class="container relative">
    <div class="content">
      <div :class="[
          'd-flex justify-space-between',
          smAndDown ? 'flex-column align-center' : 'gap-4'
        ]"
      >
        <div class="workers">
          <div
            v-for="worker in workers"
            :key="worker.id"
            class="worker d-flex align-center gap-5"
          >
            <VImg
              width="120" height="148"
              :src="worker.src"
              class="worker-img"
            />
            <div>
              <span class="primary-content font-weight-600">
                {{ worker.name }}
              </span>
              <div
                class="secondary-content mt-2"
                v-html="worker.position"
              />
            </div>
          </div>
          <p
            v-if="mdAndUp"
            class="secondary-content mt-10"
          >
            Дізнатись більше про “ФРАТЕР” та його проєкти можна
            <a
              href="https://frater.ngo"
              target="_blank"
              class="here-link text-underline pointer"
            >
              тут
            </a>
          </p>
        </div>
        <div class="about">
          <h2 :class="[
              'primary-title mb-10',
              smAndDown ? 'text-center' : ''
            ]"
          >
            Про проєкт
          </h2>
          <div class="primary-content">
            <p class="font-weight-600">
              Проєкт #БезБолюUA (#StopPainUA) - спільна ініціатива волонтерів ГО “ФРАТЕР” та військових лікарів-альгологів.
            </p>
            <br />
            <p>
              Ми об’єдналися, щоби:
            </p>
            <br />
            <ul class="ml-2">
              <li>
                звернути увагу суспільства на проблему хронічного болю у ветеранів;
              </li>
              <li>
                скоротити шлях до якісної допомоги;
              </li>
              <li>
                забезпечити ветеранам, що живуть з болем, кращу якість життя.
              </li>
            </ul>
            <br />
            <p class="font-weight-600">
              Наша мета - допомогти тобі повернутися до активного життя без болю. Відчувай нашу підтримку на кожному етапі до одужання.
            </p>
            <br />
            <p class="font-weight-600">
              Серед нас теж є ветерани; ми розуміємо твої потреби і гарантуємо конфіденційність та повагу до твого досвіду.
            </p>
            <br />
            <p class="text-body-2 font-weight-600">
              Разом долати біль легше!
            </p>
            <br />
            <p>
              Допомога надається безкоштовно, але проєкт можна підтримати тут:
            </p>
          </div>
          <SupportBtnProject class="mt-10" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./project-section.js"></script>

<style lang="scss" scoped>
.project-section {

  .project-bg {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .workers {
    margin-top: 78px;
    order: 1;
  }

  .worker:not(:last-child) {
    margin-bottom: 40px;
  }

  .worker-img {
    border-radius: 15px;
  }

  .about {
    max-width: 500px;
    order: 2;
  }

  .here-link {
    transition: .2s linear;
    &:hover {
      color: $primary;
    }
  }
}

@media only screen and (max-width: $sm) {
  .project-section {

    .pixel-1 {
      position: absolute;
      top: 118px;
      left: -187px;
    }

    .pixel-2 {
      position: absolute;
      left: -206px;
      bottom: 40px;
    }

    .worker:not(:last-child) {
      margin-bottom: 26px;
    }

    .workers {
      margin-top: 40px;
      order: 2;
    }

    .about {
      max-width: 100%;
      order: 1;
    }
  }
}
</style>
