<template>
  <div class="video-component pointer">
    <div
      class="video-preview-wrap relative"
      @click="toggleDialog"
    >
      <VImg
        isCover
        :width="smAndDown ? '100%' : '300'"
        :height="smAndDown ? '54' : '167'"
        src="video_preview.png"
        class="video-preview"
      />
      <div class="play-wrap primary-blur d-flex align-center justify-center">
        <VSheet
          :width="smAndDown ? '34' : '82'"
          :height="smAndDown ? '34' : '82'"
          class="play-round d-flex align-center justify-center"
        >
          <VImg
            isIcon
            background="play-bg"
            :width="smAndDown ? '20' : '48'"
            :height="smAndDown ? '20' : '48'"
            src="play.svg"
            class="play"
          />
        </VSheet>
      </div>
    </div>
    <VDialog
      v-model="isShowDialog"
      @close-dialog="toggleDialog"
    >
      <div :class="[
          'white pt-5 pb-10',
          smAndDown ? 'px-5' : 'px-10'
        ]"
      >
      <div class="divider" />
      <div
        v-if="isLoading"
        class="loader-wrap d-flex align-center justify-center"
      >
        <VLoader />
      </div>
      <YouTube
        v-show="!isLoading"
        ref="youtube"
        :src="src"
        @ready="onReady"
        @error="onError"
        width="100%"
      />
      </div>
    </VDialog>
  </div>
</template>

<script src="./video-component.js"></script>

<style lang="scss" scoped>
.video-preview-wrap {
  width: 300px;
  height: 167px;
  overflow: hidden;
  border-radius: 15px;
  flex-shrink: 0;

  .play-bg {
    background-color: white;
  }

  .play-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .play-round {
      position: relative;
      border-radius: 50%;
      border: 2px solid rgba($color: white, $alpha: 0.4);
    }
  }

  &:hover {
    .play-bg {
      background-color: $primary;
    }
  }
}

.divider {
  height: 52px;
}
.loader-wrap {
  height: 360px;
}

.primary-blur::before {
  height: 100%;
  backdrop-filter: blur(2px) !important;
  -webkit-backdrop-filter: blur(2px) !important;
}

@media only screen and (max-width: $sm) {
  .video-preview-wrap {
    width: 100%;
    height: 54px;
    border-radius: 10px;
  }
}
</style>
