<template>
<div
  v-if="isIcon"
  :class="[ 'v-image-mask', background ]"
  :style="{
    '-webkit-mask': `url(${url}) ` + 'center center / contain no-repeat',
    width: getParsedValue(width),
    height: getParsedValue(height)
  }"
/>
<img v-else :src="url"
  class="v-image"
  :style="{
    width: getParsedValue(width),
    height: getParsedValue(height),
    objectFit: isCover ? 'cover' : 'contain'
  }"
/>
</template>

<script src="./v-img.js">
</script>

<style lang="scss" scoped>
.v-image {
  object-position: center;
}
.v-image-mask, .v-image {
  transition: background-color .2s linear;
}
</style>
