<template>
  <div
    class="carousel relative"
    v-touch:swipe.left="swipeNext"
    v-touch:swipe.right="swipePrev"
  >
    <div
      class="carousel-inner"
      :style="{ height: height + 'px' }"
    >
      <CarouselIndicators
        v-if="indicators"
        :total="total"
        :current-index="currentSlide"
        @switch="switchSlide($event)"
      />
      <div
        v-for="(slide, index) in slides"
        :key="index"
      >
        <transition
          :name="transitionEffect"
          :slide="slide"
        >
          <div
            class="carousel-item"
            v-if="currentSlide === index"
            @mouseenter="mouseenterHandler"
            @mouseout="mouseoutHandler"
          >
            <slot :carouselItem="slide">
              {{ slide }}
            </slot>
          </div>
        </transition>
      </div>
      <CarouselControls
        v-if="controls && slides && slides.length > 1"
        @prev-slide="prev"
        @next-slide="next"
      />
    </div>
  </div>
</template>

<script src="./v-carousel.js"></script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  justify-content: center;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 0.2s ease-in-out;
}
.slide-in-enter-from {
  transform: translateX(-100%);
}
.slide-in-leave-to {
  transform: translateX(100%);
}
.slide-out-enter-from {
  transform: translateX(100%);
}
.slide-out-leave-to {
  transform: translateX(-100%);
}
</style>
