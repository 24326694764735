<template>
<Transition name="modal">
  <div
    v-if="modelValue"
    class="dialog-wrapper"
  >
    <div class="modal-mask">
      <div
        class="v-dialog-content relative margin-auto"
        :style="{ maxWidth: maxWidth + 'px' }"
      >
        <VImg
          isIcon
          width="28" height="28"
          src="close-dialog-icon.svg"
          class="close-dialog pointer"
          :background="isCloseHovered ? 'primary' : 'light-dark'"
          @mouseenter="isCloseHovered = true"
          @mouseout="isCloseHovered = false"
          @click="closeDialog"
        />
        <div class="marker" />
        <slot></slot>
      </div>
    </div>
  </div>
</Transition>
</template>

<script src="./v-dialog.js"></script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: fixed;
  z-index: 4000;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba($color: white, $alpha: 0.8);
}
.modal-mask {
  position: relative;
  max-height: 100dvh;
  height: 100dvh;
  overflow-y: auto;
}
.v-dialog-content {
  width: calc(100% - 40px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
  z-index: 200;
}

.modal-enter-active {
  animation: modal-in 0.2s;
}
.modal-leave-active {
  animation: modal-in 0.2s reverse;
}

.close-dialog {
  position: absolute;
  top: 20px;
  right: 20px;
}

.marker {
  width: 2px;
  height: 32px;
  border-radius: 0px 3px 3px 0px;
  background: #DBC9B0;
  position: absolute;
  top: 20px;
  left: 0;
}

@keyframes modal-in  {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
</style>
